import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import './callform.css';
const stamenslogo = `${process.env.PUBLIC_URL}/images/logo.svg`;

function Callform() {

    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: ''
      });

      const [errors, setErrors] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: '',
        captcha: ''
      });

      
      const URL = `${process.env.REACT_APP_CONT_URL}`+'/api/Enquire'

      const formRef = useRef();

      const [captcha, setCaptcha] = useState('');
      const [captchaInput, setCaptchaInput] = useState('');

      const generateCaptcha = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptcha(result);
      };
    
      useEffect(() => {
        generateCaptcha();

        const handleHide = () => {
          setFormData({
            Name: '',
            Email: '',
            Phone: '',
            Message: ''
          });
          setCaptchaInput('');
          setErrors({});
          generateCaptcha();
        };
    
        const modalElement = document.getElementById('exampleModal');
        if (modalElement) {
          modalElement.addEventListener('hidden.bs.modal', handleHide);
        }
    
        // Clean up the event listener when the component is unmounted
        return () => {
          if (modalElement) {
            modalElement.removeEventListener('hidden.bs.modal', handleHide);
          }
        };

      }, []);


      const validateName = (name) => {
        const nameRegex = /^[A-Za-z]+$/;
        return nameRegex.test(name);
      };

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhone = (phone) => {
        // Regular expression for validating phone numbers with optional "+" and optional space
        const phoneRegex = /^\+?[\d\s\-()]{7,15}$/;
        return phoneRegex.test(phone);
      };


      const handleChange =(e)=>{
        const { name, value } = e.target;
        if(name === 'captcha'){
          setCaptchaInput(value);
        }else {
        setFormData(prevState =>({
            ...prevState,
            [name] : value
        }));
      }
        
      }

      const handleSubmit =(e)=>{
        e.preventDefault();
        let valid = true;
        let newErrors = {}; 

        if(!formData.Name){
            valid = false;
            newErrors.Name = 'Name is required';
        }else if(!validateName(formData.Name)){
            valid = false;
            newErrors.Name = 'Only characters are allowed in the name!';
        }

        if(!formData.Email){
            valid = false;
            newErrors.Email = 'Email is required';
        }else if(!validateEmail(formData.Email)){
            valid = false;
            newErrors.Email = 'Not a valid mail';
        }
     
        if(!formData.Phone){
            valid = false;
            newErrors.Phone = 'Phone is required';
        }else if(!validatePhone(formData.Phone)){
            valid = false;
            newErrors.Phone = 'Not a number';
        }

        if(!captchaInput){
            valid = false;
            newErrors.captcha = 'Enter Captch';
          }else if(captcha !== captchaInput){
            valid = false;
            newErrors.captcha = 'CAPTCHA does not match';
            generateCaptcha();
          }

        if(valid){
       

        fetch(URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          })
          .then(response => {
            if (!response.ok) {
              
              throw new Error(`Failed to send email: ${response.statusText}`);
            }
            return response.json(); 
          })
          .then(() => {
            
            alert('Mail sent successfully');
            if (formRef.current) {
              formRef.current.reset(); 
            }
            setCaptchaInput(''); 
            setErrors({}); 
            generateCaptcha(); 
          })
          .catch(error => {
         
            alert('Failed to send email. Please try again later.');
          });

        setFormData({ 
            Name: '',
            Email: '',
            Phone: '',  
            Message: ''
          });
        setCaptchaInput('');
        generateCaptcha();
        setErrors({});
        }else{
            setErrors(newErrors);
        }
      }

  return (

    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content popup-modal-details">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                <div className="modal-body">
                    <div className="details">
                        <Link className="pb-2 ps-2 d-block" to="#"><img src={stamenslogo} alt="main logo" height="53" width="124" className="img-fluid" /></Link>
                        <div className="part">
                            <p className="heading"><i className="fa fa-envelope" aria-hidden="true"></i> Write to us</p>
                            <Link to="mailto:contact@stamenssoftware.com">contact@stamenssoftware.com</Link>
                        </div>
                        <div className="part">
                            <p className="heading"><i className="fa fa-phone" aria-hidden="true"></i> Telephone</p>
                            <Link to="tel:+911203500847" className="d-block">+91 120 350 0847</Link>
                            <Link to="tel:+911203500848" className="d-block">+91 120 350 0848</Link>
                        </div>
                        <div className="part">
                            <p className="heading"><i className="fa fa-location-dot" aria-hidden="true"></i> Location</p>
                            <p>B-33, 2nd Floor, Sector 6, Noida, UP, India - 201301</p>
                        </div>
                    </div>
                    <div className="query-form">
                        <h5>Request Free Consultation</h5>
                        <p>Please fill the form below and one of our technical consultant will get in touch with you shortly</p>
                        <form id="quey-form" onSubmit={handleSubmit} ref={formRef} noValidate >
                            <div className="mb-2">
                                <label for="name" className="form-label d-none">Name</label>
                                <input type="text" className="form-control" id="name" name="Name" placeholder="Name" value={formData.Name} onChange={handleChange}/>
                                {errors.Name && <small id="nameHelp" className="form-text text-danger">{errors.Name}</small>}
                            </div>
                            <div className="mb-2">
                                <label for="email" className="form-label d-none">Email</label>
                                <input type="text" className="form-control" id="email" name="Email" placeholder="Email" value={formData.Email} onChange={handleChange}/>
                                {errors.Email && <small id="emailHelp" className="form-text text-danger">{errors.Email}</small>}
                            </div>
                            <div className="mb-2">
                                <label for="phone" className="form-label d-none">Phone</label>
                                <input type="tel" className="form-control" id="phone" name="Phone" placeholder="Phone no."  value={formData.Phone} onChange={handleChange}/>
                                {errors.Phone && <small id="phoneHelp" className="form-text text-danger">{errors.Phone}</small>}
                            </div>
                            <div className="mb-2">
                                <label for="message" className="form-label d-none">Message</label>
                                <textarea name="Message" id="Message" cols="30" rows="5" className="form-control" placeholder="Message" value={formData.Message} onChange={handleChange}></textarea>
                            </div>
                            <div className="col-md-12 my-3" id="captcha-container">
                                <div>
                                    <label htmlFor="captcha" className="form-label d-none">Enter Captcha:</label>
                                    <span id="captchaDisplay" className="captcha-display py-2 mb-1">{captcha}</span>
                                </div>
                                <div>
                                  <div style={{display: 'flex'}}> 
                                    <input type="text" className="form-control w-50" id="captcha-input" name="captcha" placeholder="Enter Captcha" value={captchaInput} required onChange={handleChange}/>
                                    <button type='button' id="refreshCaptcha" className="btn btn-secondary" onClick={generateCaptcha} style={{marginLeft: '2%', height: '50%'}}>Refresh CAPTCHA</button>
                                    </div>
                                    {errors.captcha && <small id="captchaHelp" className="form-text text-danger py-2">{errors.captcha}</small>}
                                </div>
                            </div>
                            <div className="mb-2 mt-4 text-center">
                                <button type="submit" className="custom-btn" name="submit" value="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default Callform;