import React from "react";
import Home from "./pages/home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
// import Footer from "./components/footer";
import About from "./pages/about";
import Services from "./pages/services";
import Contact from "./pages/contact";
import Career from "./pages/career";
import CallFormButton from "./components/callformbutton";
import Callform from "./components/callform";
import LegalNotice from "./pages/legalNotice";
import PrivacyPolicy from "./pages/privacyPolicy";
import Termsandconditions from "./pages/termsandconditions";
import ScrollToTop from "./components/scrolltotop";
import Gallery from "./pages/gallery";
import Scheduleacall from "./components/scheduleacall";
import Customsoftwaredevelopment from "./pages/customsoftwaredevelopment";

function App() {
  return (
    <Router>
      <div className="">
        <Header />
        <CallFormButton />
        <Callform />
        <Scheduleacall />
        <ScrollToTop />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<Career />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/legalnotice" element={<LegalNotice />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsandconditions" element={<Termsandconditions />} />
            <Route path="/customsoftwaredevelopment" element={<Customsoftwaredevelopment />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
