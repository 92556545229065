import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Callform from "./callform";
const stamenslogo = `${process.env.PUBLIC_URL}/images/logo.svg`;
const S1 = `${process.env.PUBLIC_URL}/images/menu-icon/s-1.png`;
const S2 = `${process.env.PUBLIC_URL}/images/menu-icon/s-2.png`;
const S3 = `${process.env.PUBLIC_URL}/images/menu-icon/s-3.png`;
const S4 = `${process.env.PUBLIC_URL}/images/menu-icon/s-4.png`;
const S5 = `${process.env.PUBLIC_URL}/images/menu-icon/s-5.png`;
const S6 = `${process.env.PUBLIC_URL}/images/menu-icon/s-6.png`;
const S7 = `${process.env.PUBLIC_URL}/images/menu-icon/s-7.png`;
const S8 = `${process.env.PUBLIC_URL}/images/menu-icon/s-8.png`;
const S9 = `${process.env.PUBLIC_URL}/images/menu-icon/s-9.png`;


function  Header() {

  // const [showForm, setShowForm] = useState(false);

  // const handleClick = () => {
  //   setShowForm(true); 
  // };


  return (
    <header>
        <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/"><img src={stamenslogo} alt="main logo" height="53" width="124" className="img-fluid" /></Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li class="nav-item dropdown">
                    <Link className="nav-link" to="/service">Services</Link>
                  <button class="navbar-toggler" type="button" data-bs-toggle="dropdown" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="dropdown-toggle"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li class="menu-head">Our Software Development Services</li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <ul class="menu-list">
                        <li><Link to='/customsoftwaredevelopment'><img src={S5} alt="s-5.png" /> Custom Software Development</Link></li>
                        <li><a href="#"><img src={S2} alt="s-2.png" /> Mobile APP Development ( Android & iOS )</a></li>
                        <li><a href="#"><img src={S1} alt="s-1.png" /> Web Development Services</a></li>
                        <li><a href="#"><img src={S8} alt="s-8.png" /> Desktop Applications</a></li>
                        <li><a href="#"><img src={S3} alt="s-3.png" /> E-commerce Development</a></li>
                        <li><a href="#"><img src={S6} alt="s-6.png" /> Digital Marketing Services</a></li>
                        <li><a href="#"><img src={S9} alt="s-9.png" /> MVP Development</a></li>
                        <li><a href="#"><img src={S4} alt="s-4.png" /> Cloud Services (DevOps, AWS, GCP, Azure)</a></li>
                        <li><a href="#"><img src={S7} alt="s-4.png" /> UI/UX Development</a></li>
                        <li><a href="#"><img src={S9} alt="s-4.png" /> Hire Dedicated Development Team</a></li>
                        <li><a href="#"><img src={S1} alt="s-4.png" /> API Development</a></li>
                        <li><a href="#"><img src={S2} alt="s-4.png" /> Application Maintenance</a></li>
                        <li><a href="#"><img src={S3} alt="s-4.png" /> Quality Assurance (QA Testing)</a></li>
                        <li><a href="#"><img src={S4} alt="s-4.png" /> Business Process Outsourcing</a></li>
                        <li><a href="#"><img src={S5} alt="s-4.png" /> Migrations & Upgrades</a></li>
                        <li><a href="#"><img src={S6} alt="s-4.png" />Database Administration</a></li>
                        <li><a href="#"><img src={S7} alt="s-4.png" /> Desktop Application Development</a></li>
                        <li><a href="#"><img src={S8} alt="s-4.png" /> DevOps & Solution Architecture</a></li>
                        <li><a href="#"><img src={S9} alt="s-4.png" /> Marketing, PR & Brand Strategy</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/gallery">Gallery</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/career">Career</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact">Contact</Link>
                    </li>
                    <Link className="nav-link call-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Work with Us
                </Link>
                </ul>
              </div>
            </div>
        </nav>
    </header>
  );
}

export default Header;