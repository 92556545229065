import React from "react";

function Sidenavbar({ items }) {
  return (
    <div id="list-example" className="list-group pp-pos">
      {items.map((item, index) => (
        <a
          key={index}
          href={`#${item.id}`}
          className={`list ${window.location.hash === `#${item.id}` ? 'active' : ''}`}
        >
          <span>{item.text}</span>
        </a>
      ))}
    </div>
  );
}

export default Sidenavbar;