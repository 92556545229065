import React, { useEffect } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper,SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './gallery.css';
import Footer from '../components/footer';
import Aos from 'aos';
import 'aos/dist/aos.css';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css'; 
import Isotope from 'isotope-layout';


const galleryItems = [
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-1.jpg`, title:'App 1', filter: 'filter-app'},
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-2.jpg`, title:'Web 3', filter: 'filter-web'},
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-3.jpg`, title:'App 2', filter: 'filter-app'},
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-4.jpg`, title:'Card 2', filter: 'filter-card'},
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-5.jpg`, title:'Web 2', filter: 'filter-web'},
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-6.jpg`, title:'App 3', filter: 'filter-app'},
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-7.jpg`, title:'Card 1', filter: 'filter-card'},
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-8.jpg`, title:'Card 3', filter: 'filter-card'},
    {src: `${process.env.PUBLIC_URL}/images/gallery/portfolio-9.jpg`, title:'Web 3', filter: 'filter-web'},
   ]


function Gallery() {     

    useEffect(() => {
        Aos.init({
          duration: 1000,
          easing: 'ease-in-out',
          once: true,
          mirror: false
        });
    
        GLightbox({
          selector: '.portfolio-lightbox',
        });
    
        const portfolioContainer = document.querySelector('.portfolio-container');
        
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: '.portfolio-item',
        });
    
        const filters = document.querySelectorAll('#portfolio-flters li');
        filters.forEach(filter => {
          filter.addEventListener('click', (e) => {
            filters.forEach(f => f.classList.remove('filter-active'));
            e.target.classList.add('filter-active');
            portfolioIsotope.arrange({
              filter: e.target.getAttribute('data-filter'),
            });
            portfolioIsotope.on('arrangeComplete', () => {
                Aos.refresh();
              });
          });
        });

        const images = portfolioContainer.querySelectorAll('img');
        let loadedImagesCount = 0;
    
        images.forEach(img => {
            img.addEventListener('load', () => {
                loadedImagesCount += 1;
                if (loadedImagesCount === images.length) {
                    portfolioIsotope.layout();
                }
            });
        });

      }, []);
    

  return (
   
<>

<section className="custom-banner">
    <div className="container">
        <h1 className="banner-head text-center">
            <span>Our gallery</span>
            Stamens Software Gallery
        </h1>
    </div>
</section>



<section id="portfolio" className="portfolio">
                <div className="container" data-aos="fade-up">
                    <div className="text-center">
                        <h2 className="head">Gallery</h2>
                    </div>

                    <ul id="portfolio-flters" className="d-flex justify-content-center flex-wrap gap-3" data-aos="fade-up" data-aos-delay="100">
                    <li data-filter="*" className="filter-active">All</li>
                    <li data-filter=".filter-app">Office</li>
                    <li data-filter=".filter-card">Front Area</li>
                    <li data-filter=".filter-web">Staff</li>
                </ul>
                
                    <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                        {galleryItems.map((item, index) => (
                            <>
                            <div key={index} className={`col-lg-4 col-md-6 portfolio-item ${item.filter}`}>
                                <div className="portfolio-img">
                                    <img src={item.src} className="img-fluid" alt={item.title} />
                                </div>
                                <div className="portfolio-info">
                                    <a href={item.src} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title={item.title}>
                                        <i className="fa fa-plus"></i>
                                    </a>
                                </div>
                            </div>
                            
                        </>
                        ))}
                        
                    </div>
                </div>
            </section>

<section className="gallery-slide">
                <div className="container">
              
                    <Swiper
                        modules={[Autoplay, Pagination]}
                        spaceBetween={30}
                        slidesPerView={5}
                        slidesPerGroup={2}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: true,
                          }}
                     className="swiper mySwiper3"
                     >
                        <div className="swiper-wrapper">
                            {galleryItems.map((item, index) => (
                                <SwiperSlide key={index} className="swiper-slide">
                                    <img src={item.src} className="img-fluid" alt={`image ${index + 1}`} />
                                </SwiperSlide>
                            ))}
                        </div>
               
                    </Swiper>

                </div>
            </section>

<Footer />
</>

  )

}

export default Gallery;